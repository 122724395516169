import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React, { ReactNode, useEffect, useState } from "react";
import { LAUNCH_DARKLY_BASE_CONFIG } from "src/constants/launchDarkly";
import { useAuth } from "../../hooks/contexts/useAuth";

export const FeatureFlagProvider = (
  props: React.PropsWithChildren<unknown>
) => {
  const [Provider, setProvider] = useState<
    (({ children }: { children: ReactNode }) => JSX.Element) | undefined
  >(undefined);
  const { profile } = useAuth();

  useEffect(() => {
    async function fetchData() {
      const provider = await asyncWithLDProvider({
        ...LAUNCH_DARKLY_BASE_CONFIG,
        context: {
          kind: "multi",
          user: {
            key: profile.userId,
            name: profile.name,
            email: profile.email,
          },
        },
      });

      setProvider(() => provider);
    }

    void fetchData();
  }, [profile.userId, profile.name, profile.email]);

  if (Provider) {
    return <Provider>{props.children}</Provider>;
  }
  return null;
};
