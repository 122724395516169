import { Box } from "@mui/system";
import * as ld from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { LAUNCH_DARKLY_BASE_CONFIG } from "src/constants/launchDarkly";
import { useFeatures } from "src/service/featureFlags";
import * as SVG from "../../../components/shared/atoms/icon/icons-svg";
import { NoAccessPlaceholder } from "../molecules/NoAccessState";

const Inner: React.FC = ({ children }) => {
  const { maintenanceMode } = useFeatures();

  if (maintenanceMode) {
    return (
      <Box height="100%" display="flex" justifyContent="center">
        <NoAccessPlaceholder
          icon={SVG.NoAccessArchivedEmptyStateSVG}
          title="The Spec Sheet is undergoing maintenance"
          description="Normal service will resume shortly"
          showRefresh={false}
        />
      </Box>
    );
  }
  return <>{children}</>;
};

export const ReleaseLockoutWrapper: React.FC = ({ children }) => {
  const [Provider, setProvider] = useState<
    (({ children }: { children: React.ReactNode }) => JSX.Element) | undefined
  >(undefined);

  useEffect(() => {
    async function fetchData() {
      const provider = await ld.asyncWithLDProvider(LAUNCH_DARKLY_BASE_CONFIG);

      setProvider(() => provider);
    }

    void fetchData();
  }, []);

  return Provider ? (
    <Provider>
      <Inner>{children}</Inner>
    </Provider>
  ) : null;
};
