import { datadogRum } from "@datadog/browser-rum";
import { getApplicationVersion, R, run } from "@specsheet-common/shared-tools";
import { N_UserProfile } from "@specsheet-common/shared-types";
import { getEnv, isLocalEnv } from "src/constants/util";
import axios from "axios";

let isSetup = false;

export const setup = (recordAllSessions: boolean) => {
  if (isLocalEnv()) {
    return;
  }
  datadogRum.init({
    applicationId: "621aab26-8ab5-4cdf-b2a2-20a4cc02e720",
    clientToken: "pub0079f9b157e97aaa36837aa6ece2cd09",
    site: "datadoghq.com",
    service: "the-spec-sheet",
    env: getEnv(),
    version: getApplicationVersion(),
    sessionSampleRate: 100,
    sessionReplaySampleRate: recordAllSessions ? 100 : 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event) => {
      if (event.type === "view") {
        event.view.url = document.location.href;
      }
    },
  });

  datadogRum.startSessionReplayRecording();

  isSetup = true;
};

export const setUser = (profile: N_UserProfile) => {
  datadogRum.setUser({
    email: profile.email,
    id: profile.userId,
    name: profile.name + " " + profile.lastName,
  });
};

/**
 * Every error encountered on page should be funneled through this method
 */
export const error = (e: unknown, context: object = {}) => {
  if (isSetup) {
    // eslint-disable-next-line no-console
    console.warn("error delegated to datadog", e, context);
    const message = R.isError(e) ? e.message : undefined;
    const data = run(() => {
      if (axios.isAxiosError(e)) {
        const axiosResponse = e.response;
        const axiosRequest = e.config;
        return {
          ...context,
          isAxiosError: true,
          axiosResponse,
          axiosRequest: R.mapValues({ ...axiosRequest?.headers }, (v, k) =>
            /authori.ation/i.test(String(k)) ? "" : v
          ),
          message,
        };
      } else {
        return { ...context, message };
      }
    });
    datadogRum.addError(e, data);
  } else {
    // eslint-disable-next-line no-console
    console.error("datadog not setup to handle error", e, context);
  }
};
