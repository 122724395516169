import { getEnv, isLocalEnv } from "./util";
import * as ld from "launchdarkly-react-client-sdk";

const clientSideId: Record<string, string> = {
  prod: "65233fff85757f1341fa1208",
  "app.prod": "65233fff85757f1341fa1208",
  "app.staging": "6528a0247e8abe121bd0c4de",
  "app.testing": "65233fff85757f1341fa1207",
  "app.develop": "6528a0297e8abe121bd0c4e9",
  local: "6528a02e7e8abe121bd0c4f2",
};

export const LAUNCH_DARKLY_BASE_CONFIG: ld.AsyncProviderConfig = {
  clientSideID: clientSideId[getEnv()],
  options: {
    sendEvents: !isLocalEnv(),
  },
  context: {
    kind: "user",
    key: "abc123",
    name: "System User",
    email: "support@thespecsheet.com",
  },
};
